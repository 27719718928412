@import "../../_globalColor";

.footer-text {
  text-align: center;
  color: $subTitle !important;
}

.footer-disclaimer {
  text-align: right;
  color: $subTitle !important;
  font-size: xx-small;
}

.email {
  margin: 10px;
}

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.footer-quote {
  font-style: italic;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  color: $subTitle !important;
}