@import "../../_globalColor";

.software-skills-main-div {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.software-text-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.head {
  font-size: 19px;
  ;
  margin: auto;
  flex: 1 1;
  line-height: 1.5rem;
}

.dev-icons {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  text-align: center;
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.software-skill-inline {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
}

.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}

.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}

@media (max-width: 1500px) {
  .software-text-div {
    flex-direction: column;
    margin-top: 20px;
  }

  .dev-icons {
    justify-content: center;
  }
  
}